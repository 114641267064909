<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/role')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">
                    <div>
                         <div class="">
                            <span class="font-semibold text-base">Ubah Role</span>
                        </div>
                        <div class="grid grid-cols-12 gap-4 mt-4">
                     
                       <div class="col-span-12">
                           
                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="postData()" class="w-full" >
                            <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name" class="grid grid-cols-12 gap-4 items-center">
                            <label for="" class="block mb-2 col-span-2">Nama*</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.name"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="name"
                                ref="name"
                                id="name"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>

                            <div class="mt-4" v-if=" user.role_id == 32 || user.role_id == 41">
                                <table class="table-auto w-full text-xs overflow-x-auto">
                                    <tr class="text-left">
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">Name</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-center"></th>
                                    </tr>
                                    <tbody v-for="item in menu" :key="item.id">
                                        <tr >
                                            <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-left">{{item.name}}</th>
                                            <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-center">
                                                <input type="checkbox" name="menu_id[]" v-model="addData.menu_id" :value="item.id" id="">
                                            </th>
                                        </tr>
                                        <template v-if="item.Menus.length > 0"> 
                                            <tr v-for="child in item.Menus" :key="child.id">
                                            <th class=" text-gray-500 py-2 px-6 border border-gray-100 text-left"> - {{child.name}}</th>
                                            <th class=" text-gray-500 py-2 px-6 border border-gray-100 text-center">
                                                <input type="checkbox" name="menu_id[]" v-model="addData.menu_id" :value="child.id" id="">
                                            </th>
                                            </tr>
                                        </template>
                                    </tbody>
                                
                                
                                </table>
                            </div>

                           
                            <div class="float-right">
                            <button
                                @click="$router.push('/role')"
                                :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Batal</button>

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 biru
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>

                            
                        </form>
                        </ValidationObserver>
                       </div>
                    </div>
                    </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows:100,
        perPage:10,
        limit:5,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
       menu:[],
        addData:{
            name:'',
            menu_id:[]
         
        },
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
      
        async postData(){
           
            this.axios.put('v1/role/' + this.$route.params.id,this.addData,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/role')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },

        async getData() {
            await this.axios.get('v1/role/' + this.$route.params.id,this.config)
            .then(async (ress) => {
                
                this.addData = ress.data
                this.addData.menu_id = []
                ress.data.Menus.forEach(element => {
                    this.addData.menu_id.push(element.id)
                });
               
            })
        },
  
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    async created() {

        await this.getData()  
      this.getMenu()

    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>